/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import * as kwk from "kwk-analytics";
import { errorMiddleware } from './utils/errorLogging';
import createReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

const dontLog = {
  'CLOSE_SNACK_BAR' : true,
  'OPEN_SNACK_BAR' : true,
  '@@router/LOCATION_CHANGE' : true,
};

// eslint-disable-next-line no-unused-vars
const kwkLogger = kwk.createReduxLogger(null,
    [kwk.ActionFilterReduxForm, action => {
        if(dontLog[action.type]) return true;
        return action.type.indexOf('LOAD') > -1;
    }]);


export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, errorMiddleware, routerMiddleware(history), kwkLogger];

  if (process.env.NODE_ENV !== 'production') {
    /* eslint-disable global-require */
    // const reduxLogger = require('redux-logger');

    // Usually don't log redux-form actions because they are too spammy.
    // const logger = reduxLogger.createLogger({ predicate: (_, action) => !action.type.includes('@@redux-form') });
    // middlewares.push(logger);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than
          // once after hot reloading. Prevent recomputing reducers for `replaceReducer`.
          shouldHotReload: false,
        })
      : compose;
  /* eslint-enable */

  const store = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
